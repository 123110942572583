import React, { Component } from 'react'
import candidatesData from '../data/candidates'
import Candidate from '../candidate/Candidate'
import Button from '../button/Button'
import Navigation from '../navigation/Navigation'
import './candidates.scss'

class Main extends Component {
  render() {
    return (
      <main className="cancidates container">
        <div className="row  justify-content-center">
          <Navigation />
        </div>
        <div className="row">
          {candidatesData.map((candidate, index) => {
            return (
              <div
                key={index}
                className="candidates__item-wrapp col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <Candidate data={candidate} />
              </div>
            )
          })}
        </div>
        <div className="text-center">
          <Button to="/">Zpět na výběr</Button>
        </div>
      </main>
    )
  }
}

export default Main
