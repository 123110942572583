import bg1 from '../assets/svg/1-light.svg'
import bg2 from '../assets/svg/2-light.svg'
import bg3 from '../assets/svg/3-light.svg'
import bg4 from '../assets/svg/4-light.svg'
import candidates from './candidates'
import main from './main'
import * as content from '../content'

export default {
  order: [
    '/kandidati',
    '/program',
    '/priority/1',
    '/priority/2',
    '/priority/3',
    '/priority/4',
    '/magazin',
    '/uspechy',
  ],
  pages: {
    '/': {
      slug: '',
      order: 0,
      notification: '1',
      title: `Jednička pro občany`,
      navigation: 'Jednička',
      text: 'Hlavní text',
      metadata: {
        description: 'Jednička pro občany popisek',
        keywords: 'ods, praha 1, jednička',
      },
      items: main.items,
    },
    '/kandidati': {
      order: 5,
      slug: '',
      notification: '',
      title: 'Kandidáti',
      navigation: 'Kandidáti',
      text: '',
      metadata: {
        description: 'Jednička pro občany popisek',
        keywords: 'ods, praha 1, jednička',
      },
      candidates: candidates,
    },
    '/program': {
      order: 6,
      slug: '',
      notification: '',
      title: 'Program',
      navigation: 'Program',
      text: content.program,
      metadata: {
        description: 'Jednička pro občany popisek',
        keywords: 'ods, praha 1, jednička',
      },
    },
    // '/jak-volit': {
    //   order: 7,
    //   slug: '',
    //   notification: '',
    //   title: 'Velký křížek pro ODS',
    //   navigation: 'Jak volit',
    //   text: content.howto,
    //   metadata: {
    //     description: 'Volební návod',
    //     keywords: 'ods, praha 1, jednička, jak, volit',
    //   },
    // },
    '/priority/1': {
      order: 1,
      slug: '',
      notification: '1',
      title: 'Dáváme Jedničku do pořádku',
      navigation: 'Priorita 1',
      background: bg1,
      text: content.p1,
      metadata: {
        description: 'Jednička pro občany popisek',
        keywords: 'ods, praha 1, jednička',
      },
    },
    '/priority/2': {
      order: 2,
      slug: '',
      notification: '2',
      title: 'Parkování na Jedničce pro rezidenty',
      navigation: 'Priorita 2',
      background: bg2,
      text: content.p2,
      metadata: {
        description: 'Jednička pro občany popisek',
        keywords: 'ods, praha 1, jednička',
      },
    },
    '/priority/3': {
      order: 3,
      slug: '',
      notification: '3',
      title: 'Jednička patří našim občanům',
      navigation: 'Priorita 3',
      background: bg3,
      text: content.p3,
      metadata: {
        description: 'Jednička pro občany popisek',
        keywords: 'ods, praha 1, jednička',
      },
    },
    '/priority/4': {
      order: 4,
      slug: '',
      notification: '4',
      title: 'Život na Jedničce pro všechny generace',
      navigation: 'Priorita 4',
      background: bg4,
      text: content.p4,
      metadata: {
        description: 'Jednička pro občany popisek',
        keywords: 'ods, praha 1, jednička',
      },
    },
    '/magazin': {
      order: 7,
      slug: '',
      title: 'Jednička',
      navigation: 'Magazín',
      text: content.info,
      metadata: {
        description: 'Jednička pro občany popisek',
        keywords: 'ods, praha 1, jednička',
      },
    },
    '/uspechy': {
      order: 8,
      slug: '',
      title: 'Co se povedlo a co plánujeme',
      navigation: 'Co se povedlo',
      text: content.uspechy,
      metadata: {
        description: 'Jednička pro občany popisek',
        keywords: 'ods, praha 1, jednička',
      },
    },
  },
}
