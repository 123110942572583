import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import Menu from '../menu/Menu'
import Popup from '../popup/Popup'
import cx from 'classnames'
import Background from '../background/Background'
import { Provider, Context } from '../context'
import { withRouter } from 'react-router-dom'
import data from '../data'
import './app.scss'

export class App extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0)
  }

  render() {
    const currentPage = data.pages[this.props.location.pathname]
    return (
      <Provider>
        <Context.Consumer>
          {context => {
            return (
              <div className={cx('main-wrap', { 'main-wrap__menu-open': context.state.menu })}>
                <Background image={currentPage.background} />
                <Menu currentPath={this.props.location} />
                <Popup />
                <div className="app">
                  <Header title={currentPage.title} notification={currentPage.notification} />
                  {this.props.children}
                  <Footer />
                </div>
              </div>
            )
          }}
        </Context.Consumer>
      </Provider>
    )
  }
}

export default withRouter(props => <App {...props} />)
