import spackovam from '../assets/candidates/1_spackova_eva_m.jpg'
import buresm from '../assets/candidates/2_bures_richard_m.jpg'
import janderovam from '../assets/candidates/10_janderova_jaroslava_m.jpg'

import situace1 from '../assets/images/situace1.jpg'
import situace2 from '../assets/images/situace2.jpg'
import situace3 from '../assets/images/situace3.jpg'
import situace4 from '../assets/images/situace4.jpg'

export default {
  items: [
    {
      title: 'Kandidáti',
      slug: 'kandidati',
      link: '/kandidati',
      images: [spackovam, buresm, janderovam],
    },
    {
      // title: 'Naše priorita',
      title: 'Dáváme Jedničku do pořádku',
      index: 1,
      image: situace3,
      slug: 'priority-1',
      link: '/priority/1',
    },
    {
      title: 'Program',
      image: '',
      slug: 'program',
      link: '/program',
    },
    {
      // title: 'Naše priorita',
      title: 'Parkování na Jedničce pro rezidenty',
      index: 2,
      image: situace1,
      slug: 'priority-2',
      link: '/priority/2',
    },
    {
      // title: 'Naše priorita',
      title: 'Jednička patří našim občanům',
      index: 3,
      image: situace4,
      slug: 'priority-3',
      link: '/priority/3',
    },
      {
        title: 'Magazín Jednička a další informace',
        // image: magazin,
        slug: 'jednicka',
        link: '/magazin',
      },
    {
      // title: 'Naše priorita',
      title: 'Jednička pro všechny generace',
      index: 4,
      image: situace2,
      slug: 'priprity-4',
      link: '/priority/4',
    },
    {
      title: 'Co se povedlo a co plánujeme',
      images: '',
      slug: 'uspechy',
      link: '/uspechy',
    },
  ],
}
