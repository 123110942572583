import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './app/App'
import Main from './main/Main'
import Detail from './detail/Detail'
import Candidates from './candidates/Candidates'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

ReactDOM.render(
  <Router>
    <App>
      <Switch>
        <Route exact path="/" render={props => <Main />} />
        <Route exact path="/priority" render={() => <Redirect to="/priority/1" />} />
        <Route
          path="/priority/:id"
          render={props => {
            console.log('priority orute')
            return <Detail {...props} />
          }}
        />
        <Route path="/kandidati" component={Candidates} />
        <Route path="/kandidati/:id" render={props => <Detail {...props} />} />
        <Route path="/program" render={props => <Detail {...props} />} />
        <Route path="/magazin" render={props => <Detail {...props} />} />
        <Route path="/uspechy" render={props => <Detail {...props} />} />
        {
          // <Route path="/prace" component={Prace} />
          // <Route path="/cile" component={Cile} />
        }
      </Switch>
    </App>
  </Router>,
  document.getElementById('root'),
)
