import React from 'react'
import Expand from '../expand/Expand'
// import Num1 from '../assets/svg/1-light.svg'
// import { Number1, Number2, Number3 } from '../assets/'
// import logo from '../assets/svg/logo-ods.svg'
import how from '../assets/images/jakvolit2.png'

import uspechy1 from '../assets/images/co_se_povedlo/02a.jpg'
import uspechy2 from '../assets/images/co_se_povedlo/02b.jpg'
import uspechy3 from '../assets/images/co_se_povedlo/02C.jpg'

import uspechy4 from '../assets/images/co_se_povedlo/03a.jpg'
import uspechy5 from '../assets/images/co_se_povedlo/03b.jpg'

import uspechy6 from '../assets/images/co_se_povedlo/05a.jpg'
import uspechy7 from '../assets/images/co_se_povedlo/05b.jpg'


export const info = () => (
  <div>
    <h3>Magazín oblastního sdružení ODS Praha 1</h3>
    <ul>
      <li>
        <a href="/magazin/jednicka_01_online.pdf">Jednička 05/2018</a>
      </li>
      <li>
        <a href="/magazin/jednicka_02_online.pdf">Jednička 06/2018</a>
      </li>
      <li>
        <a href="/magazin/jednicka_03_online.pdf">Jednička 08/2018</a>
      </li>
      <li>
        <a href="/magazin/jednicka_04_online.pdf">Jednička 09/2018</a>
      </li>
      <li>
        <a href="/magazin/jednicka_mimoradna_online.pdf">Předvolební Jednička</a>
      </li>
    </ul>
    <h3>Dokumenty</h3>
    <ul>
      <li>
        <a href="/magazin/priority_online.pdf">Naše priority</a>
      </li>
    </ul>
  </div>
)

export const p1 = () => (
  <div>
    <h2>Dáváme Jedničku do pořádku</h2>
    <h3>Chceme zásadní změnu přístupu k problému bezdomovectví v centru Prahy.</h3>
    <p>
      Prosadíme vstřícný, ale velmi důsledný soubor pravidel, který pomůže odstranit jak problémy
      lidí bez domova, tak i jejich negativní dopad na občany Prahy 1. Je nutné zefektivnit síť
      potřebných zařízení, aby měli všichni šanci na pomoc a práci. Aby však mohl každý tuto podporu
      získat, musí zcela bezpodmínečně dodržovat daná pravidla.
    </p>
    <h3>Prosadíme zavedení moderní formy domovského práva.</h3>
    <p>
      Základem dobrého soužití musí být respektování pravidel z obou stran, tedy i osobami bez
      střechy nad hlavou. Lidem, kteří dlouhodobě porušují principy dobrého soužití a nabízenou
      pomoc odmítnou, musí mít daná obec právo zakázat vstup a vrátit je do péče obce, kde mají
      trvalé bydliště.
    </p>
    <h3>Budeme i nadále dbát na krásu a čistotu centra Prahy.</h3>
    <p>
      Uklizené ulice, opravené fasády a krásná veřejná prostranství jsou dlouhodobě naší prioritou.
      Vidíme ale stále ještě neuspokojivou situaci v péči o úklid a pořádek v ulicích. Tato práce je
      v kompetenci magistrátu, vidíme však, že je nedostatečná. Praha 1 bude tedy tuto roli suplovat
      v zájmu nás všech. Proto rozšiřujeme systém odstraňování škod od sprejerů a zvětšujeme vlastní
      kapacity pro zajištění čistší Jedničky.
    </p>
  </div>
)

export const p2 = () => (
  <div>
    <h2>Parkování na Jedničce hlavně pro rezidenty</h2>
    <h3>Prosazujeme zásadní reformu parkování v historickém centru Prahy.</h3>
    <p>
      Nechceme proměnit srdce Prahy na parkoviště a současně víme, že parkovacích míst je málo.
      Proto musejí mít rezidenti a zde žijící podnikatelé zásadně přednost před všemi ostatními.
      Chceme rozlišovat parkování pro zásobování nebo jinou dopravní obsluhu od běžného parkování
      limuzín před kancelářemi.
    </p>
    <h3>Provedeme kompletní revizi parkovacích stání a navrhneme změnu pravidel jejich užívání.</h3>
    <p>
      Pokračováním důsledné revize nejrůznějších omezení lze získat desítky dalších parkovacích
      míst. Zároveň budeme požadovat, aby modré zóny nesloužily jako místo pro podnikání, např.
      nabízení služeb u takzvaných historických aut nebo jako reklamní poutač.
    </p>
    <h3>Pro auta chceme moderní, ale zároveň funkční řešení.</h3>
    <p>
      Zavedením moderních technologií omezíme zbytečný pohyb aut v centru. Díky efektivnímu
      monitoringu volných parkovacích míst na ulicích i v podzemních garážích zkrátíme řidičům
      jejich dlouhé hledání. Souběžně budeme usilovat o stavbu garáží pro rezidenty, podnikatele
      i návštěvníky a prosadíme návrat viditelného označení všech aut s parkovacím oprávněním Prahy
      1.
    </p>
  </div>
)

export const p3 = () => (
  <div>
    <h2>Jednička patří našim občanům</h2>
    <h3>
      Předložíme efektivní řešení problematiky krátkodobých pronájmů, které komplikují život lidem
      &nbsp;v centru.
    </h3>
    <p>
      Spojíme zkušenosti z Prahy 1 a magistrátu a navrhneme rychlé a účinné opatření na tento
      negativně vnímaný fenomén. Zvláštní důraz budeme klást na ty, kteří nedodržují pravidla,
      obcházejí zákony a nedbají stížností lidí ve svém okolí.
    </p>

    <h3>Zlepšením spolupráce s městskou policií budeme více chránit pořádek a klid v ulicích.</h3>
    <p>
      Turismus přináší i rušení nočního klidu, konfliktní situace a porušování pravidel. Budeme
      bojovat za to, aby městská policie výrazně navýšila stav svých strážníků. Rozvíjíme systém
      moderních bezpečnostních prvků, které jim v práci pomáhají. Chceme, aby městská policie
      soustředila svou pozornost na ty problémy, které nejvíce trápí obyvatele centra.
    </p>

    <h3>
      Budeme důsledně zasahovat proti podnikavcům v turistickém průmyslu, kteří kazí podobu i pověst
      Prahy.
    </h3>
    <p>
      Využijeme všech kompetencí Prahy 1 k tomu, aby turismus neměnil centrum v ráj podvodníků.
      Zajistíme lepší regulaci dopravy spojené s turismem a transparentní přidělování a kontrolu
      předzahrádek. Budeme nadále bojovat proti falešným průvodcům a chceme omezit organizované
      skupiny turistů přijíždějící pouze za alkoholem.
    </p>
  </div>
)

export const p4 = () => (
  <div>
    <h2>Spokojený život na Jedničce pro všechny generace</h2>
    <h3>Školy a školky na Praze 1 musejí být ty nejlepší v Praze.</h3>
    <p>
      Budeme pokračovat v podpoře vzdělávání na Praze 1 a nadále zajišťovat co nejlepší učitele,
      kterým nabídneme nadstandardní podmínky včetně možnosti bydlení. Chceme dětem poskytnout
      moderní vybavení, opravené prostory i dobré a kvalitní jídlo. Hřiště a sportoviště musejí
      sloužit jak dětem, tak i dospělým.
    </p>
    <h3>Chceme plnohodnotný společenský život pro občany Prahy 1.</h3>
    <p>
      Budeme podporovat práci tradičních i nových občanských spolků, které přinášejí možnosti
      aktivit a setkávání. Chceme, aby se spolková činnost na Praze 1 soustředila především na
      vytváření a udržování dobrých sousedských vztahů. Budeme podporovat, aby se prostřednictvím
      spolků mohla rozvíjet mezigenerační setkávání a aby se s historií Prahy 1, která se
      současnosti postupně vzdaluje, seznamovaly naše děti.
    </p>
    <h3>Zajistíme občanům Prahy 1 i nadále dostupná a dobře vybavená zdravotnická zařízení.</h3>
    <p>
      Nemocnice Na Františku musí i nadále sloužit hlavně občanům Prahy 1. Budeme trvat na zajištění
      financí i z dalších městských částí, jejichž občané nemocnici také využívají. Prosadíme její
      další modernizaci a učiníme kvalitní lékařskou péči co nejdostupnější všem potřebným
      pacientům. Chceme současně zachovat a rozvíjet polikliniku v ulici Palackého, jejíž působení
      rozšíří možnosti ošetření a péče o zdraví občanů Prahy 1.
    </p>
  </div>
)

export const uspechy = () => (
  <div>
    <ul>
      <li><img src={uspechy1} alt=""/></li>
      <li><img src={uspechy2} alt=""/></li>
      <li><img src={uspechy3} alt=""/></li>
      <li><img src={uspechy4} alt=""/></li>
      <li><img src={uspechy5} alt=""/></li>
      <li><img src={uspechy6} alt=""/></li>
      <li><img src={uspechy7} alt=""/></li>
    </ul>
  </div>
)


export const howto = () => (
  <div>
    <h2>
      <span>Jak volit ODS Praha 1</span>
    </h2>
    <p>
      Volební systém v komunálních volbách je nejsložitější a nejméně srozumitelný volební systém,
      který se u nás používá. Celkem máte 25 hlasů. Stranám se dávají tzv. velké křížky, kandidátům
      tzv. malé křížky. Preferenční hlasy, tak jak je známe třeba z voleb do Poslanecké sněmovny,
      v komunálních volbách neexistují. Nemá tedy smysl křížkovat jednotlivé kandidáty.
    </p>
    <div className="popup_item-container container">
      <div className="row">
        <div className="popup__item-wrapp col-12">
          <div className="popup__item">
            <img src={how} alt="Jak volit" />
          </div>
        </div>
      </div>
    </div>
    <p>
      ODS Praha 1 kandiduje jako jeden tým. Pokud se nás rozhodnete podpořit, dejte pouze velký
      křížek ODS. Tím podpoříte všechny naše kandidáty, vaše hlasy nepropadnou a my budeme moci
      pokračovat v práci, kterou pro vás na radnici děláme. Předem děkujeme za vaši podporu.
    </p>
    <h3>
      Přijďte k volbám<br />5. a 6. října 2018
    </h3>
  </div>
)

export const program = () => (
  <div>
    <h2>Program pro komunální volby</h2>

    <h3>Úvodní slovo</h3>

    <h4>Jednička pro občany.</h4>

    <p>
      Praha zažívá neustálý nárůst turistů, kteří se soustřeďují právě u nás, v nejkrásnější části
      města. Stejně tak postupně roste počet lidí z jiných částí Prahy i mimo ni, kteří centrum
      navštěvují za zábavou, prací či pro trávení volného času. Na zájem Čechů i cizinců o krásné
      srdce Prahy můžeme být pyšní.
    </p>

    <p>
      Bohužel tento trend s sebou přináší i nové výzvy a také bohužel nové negativní dopady. Davy
      lidí, všudypřítomné stánky a prodejny, zdražování potravin a hůře dostupné obchody i služby
      k turismu neodmyslitelně patří. Bary a organizované skupiny lidí cestujících za levným
      alkoholem ruší veřejný pořádek. Krátkodobé pronájmy přenesly hotelový průmysl a s ním spojené
      obtíže až před náš práh.
    </p>

    <p>
      Praha 1 má jen omezené možnosti, jak situaci řešit. ODS připravila a již delší čas prosazuje
      nápravu jak na Magistrátu, tak u jiných institucí, které k tomu mají pravomoc. Parkování,
      pravidla pro bydlení či průvodce, turistická vozítka a autobusy – na to vše naše radnice sama
      nestačí; musí tlačit na jiné.
    </p>

    <p>
      Pracujeme ale i na tom, aby se občanům na Praze 1 žilo lépe. Prosazujeme podporu tradičních
      spolků a sousedských aktivit. Bojujeme za nejlepší školky a školy. Suplujeme roli Magistrátu a
      investujeme do úklidu a oprav tam, kde to mají dělat jiní. Chceme, aby se lidé ve svém městě
      cítili doma.
    </p>

    <p>Jednička pro občany. </p>

    <h3>Bezdomovectví</h3>
    <Expand>
      <p>
        Chceme zásadní změnu přístupu k problému bezdomovectví v centru Prahy. Prosadíme vstřícný,
        ale důsledný soubor pravidel, který pomůže odstranit jak problémy lidí bez domova, tak i
        negativní dopady na občany Prahy 1. Chceme zefektivnit síť potřebných zařízení, aby měl
        každý šanci na pomoc a práci. Aby tuto podporu získal, musí ovšem bezpodmínečně dodržovat
        daná pravidla. Ta platí pro všechny. Proto chceme zavést moderní formu domovského práva.
        Obec musí mít možnost lidem, kteří dlouhodobě porušují principy dobrého soužití a odmítnou
        nabízenou pomoc, zakázat vstup a vrátit je do péče tam, kde mají trvalé bydliště.
      </p>

      <p>
        Řešíme situaci, kdy centrum města je bezdomovci zatíženo mnohem více než jiné městské části.
        Přítomnost turistů a například i stánků s občerstvením dává bezdomovcům šanci na získávání
        finančních prostředků i potravin. Část bezdomovců představuje zdroj konfliktů a problémů: od
        porušování zásad lidského soužití přes hygienické problémy až po přestupky a trestné činy.
      </p>
    </Expand>

    <h3>Krátkodobé pronájmy</h3>
    <Expand>
      <p>
        Chceme předložit okamžité a efektivní řešení problematiky krátkodobých pronájmů. Spojíme
        zkušenosti z Prahy 1, z Magistrátu i z Poslanecké sněmovny a navrhneme rychlé a účinné
        opatření na tento negativně vnímaný fenomén. Zvláštní důraz budeme klást na subjekty, které
        nedodržují pravidla, obcházejí zákony a nedbají stížností ve svém okolí.
      </p>

      <p>
        Řešíme tím problémy, které tyto krátkodobé pronájmy přinášejí okolním nájemníkům či
        majitelům bytů. Budeme řešit i bezpečnostní rizika včetně například protipožárních předpisů,
        které by měly tyto pronajímané byty splňovat.
      </p>
    </Expand>
    <h3>Turistický průmysl</h3>
    <Expand>
      <p>
        Chceme důsledně zasahovat proti podnikavcům v turistickém průmyslu. Prosazujeme přísná
        kritéria na průvodce památkami a budeme bojovat proti organizovaným průvodům turistů za
        alkoholem. Chceme nastavit pravidla tak, abychom mohli zlepšit estetickou úroveň těch
        prodejen, které narušují historický ráz centra Prahy. Chceme zároveň, aby na Praze 1
        vznikaly nové a zajímavé architektonické objekty, které osloví jak místní, tak i
        návštěvníky.
      </p>

      <p>
        Řešíme tím to, že negativní dopady turismu mění centrum v ráj pro různé podnikavce či přímo
        podvodníky. Řešíme tím i pověst Prahy jako ráje alkoholového turismu, kterou si rozhodně
        nezaslouží. Řešíme tím problém, že je Praha stále destinací pro problematický okruh turistů.
        Naším cílem je mít v Praze menší, ale kvalitnější druh návštěvníků.
      </p>
    </Expand>
    <h3>Městská policie</h3>
    <Expand>
      <p>
        Chceme zlepšit spolupráci s Městskou policií, kde již máme konkrétní výsledky. Budeme více
        chránit pořádek a klid v ulicích. Budeme bojovat za to, aby Městská policie výrazně navýšila
        stav svých strážníků. Chceme jim nabízet stabilizační byty a příplatky; rozvíjíme systém
        moderních bezpečnostních prvků, které jim v práci pomáhají. Chceme, aby se Městská policie
        soustředila svou pozornost na ty problémy, které nejvíce trápí obyvatele centra.
      </p>

      <p>
        Řešíme to, že cizinci i další návštěvníci centra přinášejí i rušení nočního klidu,
        konfliktní situace a porušování pravidel. Žádný krok sám o sobě pořádek nezajistí, kombinace
        mnoha námi prosazovaných opatření ale přinese jednoznačné zlepšení.
      </p>
    </Expand>
    <h3>Úklid a čistota</h3>
    <Expand>
      <p>
        Chceme i nadále dbát na krásu a čistotu centra Prahy. Uklizené ulice, opravené fasády a
        krásná veřejná prostranství jsou dlouhodobě naší prioritou. Rozšiřujeme systém odstraňování
        škod od sprejerů a zvětšujeme vlastní kapacity pro čistší Jedničku. Chceme definitivně
        prosadit systém inteligentních košů, které zásadně snižují náklady na svůj provoz a zvyšují
        čistotu svého okolí. Chceme, aby celkové náklady na jejich provoz znamenaly úsporu, ne další
        výdaje.
      </p>

      <p>
        Řešíme stále neuspokojivou situaci v péči o úklid a pořádek v ulicích. Tato práce je
        v kompetenci Magistrátu a my vidíme, že je nedostatečná. Praha 1 bude proto tuto roli
        suplovat v zájmu nás všech. Řešíme epidemii sprejerů okamžitým odstraňováním škod bez ohledu
        na to, kde je spáchali.
      </p>
    </Expand>
    <h3>Školství</h3>
    <Expand>
      <p>
        Chceme, aby školy a školky na Praze 1 byly ty nejlepší v celé Praze. Budeme pokračovat
        v podpoře vzdělávání na Praze 1 a nadále zajišťovat co nejlepší učitele, kterým nabídneme
        nadstandardní podmínky včetně možnosti bydlení. Chceme pro děti zajistit moderní vybavení,
        opravené prostory i dobré a kvalitní jídlo. Hřiště a sportoviště musejí sloužit jak dětem,
        tak dospělým.
      </p>

      <p>
        Řešíme dostupné kapacity ve školkách. Řešíme i problémy s dětmi cizinců, které neumějí
        česky, ale jsou umísťovány do MŠ a ZŠ na Praze 1. Zásadně zlepšujeme kvalitu jídla ve
        školních jídelnách.
      </p>
    </Expand>
    <h3>Spolková činnost</h3>
    <Expand>
      <p>
        Chceme, aby měli lidé na Praze 1 plnohodnotný společenský život. Budeme podporovat práci
        tradičních i nových občanských spolků, které přinášejí možnosti aktivit a setkávání. Chceme,
        aby se spolková činnost na Praze 1 soustředila především na vytváření a udržování dobrých
        sousedských vztahů. Budeme podporovat, aby se prostřednictvím spolků mohla rozvíjet
        mezigenerační setkávání a historie Prahy 1 tak byla předávána našim dětem.
      </p>

      <p>
        Řešíme zdroje i podmínky pro činnost všech užitečných spolků, aby mohly lépe pracovat a
        vnášet nejrůznější aktivity do života občanů Prahy 1. Řešíme i to, aby byla zaznamenána
        historie a paměť města, která nás postupně opouští.
      </p>
    </Expand>
    <h3>Zdravotnictví</h3>
    <Expand>
      <p>
        Chceme zajistit občanům Prahy 1 kvalitní a dostupnou zdravotní péči. Budeme trvat na
        spolufinancování nemocnice Na Františku z Magistrátu i z dalších městských částí, jejich
        občané ji také využívají. Prosadíme její další modernizaci a přineseme kvalitní lékařskou
        péči co nejblíže všem potřebným pacientům. Chceme zachovat a rozvíjet polikliniku v ulici
        Palackého, aby byla péče pro občany Prahy 1 kvalitní a dostupná.
      </p>

      <p>
        Řešíme nedostatek zdrojů a laxnost Prahy 7 podílet se na chodu nemocnice, kterou její občané
        využívají. Řešíme nevyužité prostory nemocnice Na Františku a nutnost obnovy přístrojového
        vybavení.
      </p>
    </Expand>
    <h3>Doprava</h3>
    <Expand>
      <p>
        Chceme lépe regulovat dopravu spojenou s turismem, ať už jde o takzvaná historická auta,
        čekající taxi nebo o návaly autobusů. Omezili jsme vjezd autobusů na Hradčanské náměstí
        chceme tak činit i na jiných místech. Součástí změny systému parkování bude i zákaz nabízení
        služeb z míst, kde se má pouze parkovat. Pěší zóny mají sloužit především chodcům a lidem
        k příjemnému posezení.
      </p>

      <p>
        Řešíme tím stav, kdy u hlavních turisticky vyhledávaných místech postávají reklamní poutače
        či historické vozy. Řešíme i problémy s cyklisty, kteří ohrožují chodce na chodnících nebo
        na pěších zónách. Městská policie zde musí být důslednější.
      </p>
    </Expand>
    <h3>Čistší ovzduší</h3>
    <Expand>
      <p>
        Chceme zásadním způsobem omezit znečišťování ovzduší způsobené dopravou. Naším cílem je
        proto prosadit nákladní dopravu výhradně na alternativní pohon, a to do roku 2020 na pěších
        zónách a do roku 2023 v celé památkové zóně. Chceme omezit říční dopravu jen na ty lodě,
        které splní dostatečné emisní normy.
      </p>

      <p>
        Řešíme zátěž znečištění a hluku, které centru Prahy přináší nákladní doprava. Praha 1
        potřebuje dostatečné zásobování, ale musí se tak dít šetrnější a ekologičtější cestou.
        Elektromobily i cyklodoprava jsou v této situaci správným řešením.
      </p>
    </Expand>
    <h3>Parkování</h3>
    <Expand>
      <p>
        Chceme zásadní reformu parkování v historickém centru Prahy. Rezidenti a zde žijící
        podnikatelé musejí mít úplnou přednost před všemi ostatními. Chceme rozlišovat parkování pro
        zásobování nebo jinou dopravní obsluhu od běžného parkování limuzín před kancelářemi.
        Pokračováním důsledné revize nejrůznějších omezení lze získat desítky dalších parkovacích
        míst. Souběžně budeme usilovat o stavbu garáží pro krátkodobé i dlouhodobé stání a prosadíme
        návrat viditelného označení všech aut s parkovacím oprávněním Prahy 1.
      </p>

      <p>
        Řešíme nedostatek míst ke stání i to, že si nepřejeme mít z historického centra nevzhledné
        parkoviště. Řešíme chybný systém nastavený Magistrátem, kdy si každá kancelář na Praze 1
        může koupit parkovací povolení. Vadí nám, že množství vydaných povolení je mnohem vyšší, než
        je počet fyzických parkovacích míst.
      </p>
    </Expand>
    <h3>Kultura</h3>
    <Expand>
      <p>
        Chceme, aby byla Praha 1 i nadále centrem kulturního života města a celé země. Prosazujeme,
        aby kromě velkých kulturních institucí nabízela Praha 1 svým občanům možnost využívat i
        menší galerie, kluby a projekty. Chceme, aby vedle sebe byla kultura živá i neživá, malá i
        velká. Řešíme nedostatek prostředků na péči o památky, které poskytuje Magistrát. Řešíme i
        problémy v systému podpory kulturních projektů v Praze. Mnoho zajímavých kulturních aktivit,
        zejména u začínajících umělců, se nedokáže bez určité finanční podpory realizovat. Pro tuto
        oblast sháníme zdroje i další formu pomoci.
      </p>
    </Expand>
    <h3>Moderní technologie</h3>
    <Expand>
      <p>
        Chceme pokračovat v tom, aby moderní, ale zároveň funkční technologie sloužily občanům Prahy
        1. Zavedeme do praxe aplikaci, která bude umožňovat přidělování předzahrádek on-line a
        umožní i okamžitou kontrolu. Chceme zavést moderních mobilní technologie, které omezí
        zbytečný pohyb aut v centru. Chceme, aby případné nové garáže využívaly automatizaci a byly
        díky tomu menší a levnější.
      </p>
      <p>
        Řešíme tím problém s neefektivním a netransparentním stavem přidělování a zpoplatňování
        veřejných záborů u restaurací a kaváren. Díky efektivnímu monitoringu volných míst na
        ulicích i v podzemních garážích zkrátíme řidičům dlouhé hledání místa k parkování.
      </p>
    </Expand>
  </div>
)
