import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './button.scss'

class Button extends Component {
  render() {
    return (
      <div className="button">
        <Link to={this.props.to}>{this.props.children}</Link>
      </div>
    )
  }
  static defaultProps = {
    to: '/',
  }
}

export default Button
