import React, { Component } from 'react'
import ptak from '../assets/svg/ptak.svg'
import data from '../data'

export const Context = React.createContext()
const defaultPage = data.pages['/']

export class Provider extends Component {
  state = {
    background: ptak,
    title: defaultPage.title,
    description: defaultPage.metadata.description,
    keywords: defaultPage.metadata.keywords,
    menu: false,
  }

  setBackground(image) {
    this.setState({ background: image || ptak })
  }

  setMenu(val) {
    this.setState({ menu: open })
  }

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          setBackground: this.setBackground.bind(this),
          setMenu: this.setMenu.bind(this),
        }}>
        {this.props.children}
      </Context.Provider>
    )
  }
}
