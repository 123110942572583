import React, { Component } from 'react'
import cx from 'classnames'
import MenuClose from '../menu/MenuClose'
import { howto as Howto } from '../content/'
import './popup.scss'

class Popup extends Component {
  state = {
    open: false,
  }
  togglePopup() {
    this.setState({
      open: !this.state.open,
    })
  }
  render() {
    const { open } = this.state
    return (
      <React.Fragment>
        <div className="popup popup__button">
          <span>Přijďte k volbám</span>
          <span>5. a 6. října 2018</span>
          <button onClick={this.togglePopup.bind(this)}>Víte jak volit?</button>
        </div>
        <div className={cx('popup', 'popup__container', { popup__open: open })}>
          <div className="popup__padding">
            <Howto />
            <button onClick={this.togglePopup.bind(this)}>
              <span>zpět na výběr</span>
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Popup
