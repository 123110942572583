import React from 'react'
import './notification.scss'

const Notification = ({ className, notification }) => {
  if (!notification) {
    return null
  }
  return (
    <div className={`notification ${className}`}>
      <span className="notification__text">{notification}</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.45 77.69">
        <defs>
          <clipPath id="a" transform="translate(0 0.05)">
            <rect width="97.45" height="77.65" style={{ fill: 'none' }} />
          </clipPath>
        </defs>
        <g style={{ clipPath: 'url(#a)' }}>
          <path
            d="M97.45,38.82A38.83,38.83,0,0,1,33.78,68.66L0,76.67,20.93,48.13a38.83,38.83,0,1,1,76.52-9.31"
            transform="translate(0 0.05)"
            style={{ fill: '#d21317' }}
          />
        </g>
      </svg>
    </div>
  )
}

export default Notification
