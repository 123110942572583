import React, { Component } from 'react'
import Item from '../item/Item'
import data from '../data/main'
import Logo from '../logo/Logo'
import './main.scss'

class Main extends Component {
  render() {
    // const { data } = this.props
    return (
      <main className="main">
        <div className="container">
          <div className="row">
            {data.items &&
              data.items.map((item, index) => (
                <div
                  key={index}
                  className="main__item-wrapp col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <Item {...item} />
                </div>
              ))}
          </div>
          <Logo />
        </div>
      </main>
    )
  }
}

export default Main
