import React from 'react'

const Right = () => (
  <svg viewBox="0 0 55 55">
    <circle cx="27.5" cy="27.5" r="27.5" style={{ fill: '#3fa7f3' }} />
    <line x1="18.35" y1="8.58" x2="36.65" y2="26.89" style={{ fill: 'none', stroke: '#fff' }} />
    <line x1="18.35" y1="45.2" x2="36.65" y2="26.89" style={{ fill: 'none', stroke: '#fff' }} />
  </svg>
)

export default Right
