import React, { Component } from 'react'
import Expand from './Expand'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import './item.scss'

class Item extends Component {
  state = {
    activeImageIndex: 0,
    length: 0,
    progress: 0,
  }

  progress() {
    clearInterval(this.prgint)
    this.prgint = setInterval(() => {
      let progress = this.state.progress + 1
      if (progress > 100) {
        clearInterval(this.prgint)
      } else {
        this.setState({ progress: progress })
      }
    }, 50)
  }

  rotate(length) {
    this.progress()
    this.int = setInterval(() => {
      const target = this.state.activeImageIndex + 1
      this.setState({ activeImageIndex: target >= length ? 0 : target })
      this.setState({ progress: 0 })
      this.progress()
    }, 5000)
  }
  componentDidMount() {
    if (this.props.images) {
      this.rotate(this.props.images.length)
      this.setState({ length: this.props.images.length })
    }
  }

  componentWillUnmount() {
    this.int && clearInterval(this.int)
    this.prgint && clearInterval(this.prgint)
  }

  render() {
    const { title, link, index, slug, images, image } = this.props
    const { activeImageIndex, progress } = this.state
    const isPriority = !!index
    // const NumberIcon = numbers[index]

    return (
      <div
        className={cx('item', `item__${slug}`, { item__priority: isPriority })}
        style={image ? { background: `url(${image})`, 'background-size': 'cover' } : {}}
        >
        {images && (
          <div className="item__images">
            {images.map((image, i) => {
              const visible = activeImageIndex === i
              return (
                <div
                  className={cx('item__image', { visible: visible })}
                  style={{ background: `url(${image})` }}
                  key={i}
                />
              )
            })}
            <div className="item__image-progress-wrapp">
              <div className={cx('image-progress')} style={{ width: `${progress}%` }} />
            </div>
          </div>
        )}
        <Link to={link}>
          <div className="item__wrap">
            <h2>
              <span className="item__title">{title}</span>
            </h2>
          </div>
          <Expand className="item__expand" />
        </Link>
      </div>
    )
  }
}

export default Item
