// import spackova from '../assets/candidates/1_spackova_eva.jpg'
// import bures from '../assets/candidates/2_bures_richard.jpg'
// import heres from '../assets/candidates/3_heres_tomas.jpg'
// import scholz from '../assets/candidates/4_scholz_petr.jpg'
// import marik from '../assets/candidates/5_marik_vladimir.jpg'
// import novak from '../assets/candidates/6_novak_jan.jpg'
// import bauerova from '../assets/candidates/7_bauerova_martina.jpg'
// import ouska from '../assets/candidates/8_ouska_tomas.jpg'
// import travnicek from '../assets/candidates/9_travnicek_matej.jpg'
// import janderova from '../assets/candidates/10_janderova_jaroslava.jpg'
import kandidat1 from '../assets/candidates/new/kandidat1.jpg'
import kandidat2 from '../assets/candidates/new/kandidat2.jpg'
import kandidat3 from '../assets/candidates/new/kandidat3.jpg'
import kandidat4 from '../assets/candidates/new/kandidat4.jpg'
import kandidat5 from '../assets/candidates/new/kandidat5.jpg'
import kandidat6 from '../assets/candidates/new/kandidat6.jpg'
import kandidat7 from '../assets/candidates/new/kandidat7.jpg'
import kandidat8 from '../assets/candidates/new/kandidat8.jpg'
import kandidat9 from '../assets/candidates/new/kandidat9.jpg'
import kandidat10 from '../assets/candidates/new/kandidat10.jpg'
import kandidat11 from '../assets/candidates/new/kandidat11.jpg'
import kandidat12 from '../assets/candidates/new/kandidat12.jpg'
import kandidat13 from '../assets/candidates/new/kandidat13.jpg'
import kandidat14 from '../assets/candidates/new/kandidat14.jpg'
import kandidat15 from '../assets/candidates/new/kandidat15.jpg'
import kandidat16 from '../assets/candidates/new/kandidat16.jpg'
import kandidat17 from '../assets/candidates/new/kandidat17.jpg'
import kandidat18 from '../assets/candidates/new/kandidat18.jpg'
import kandidat19 from '../assets/candidates/new/kandidat19.jpg'
import kandidat20 from '../assets/candidates/new/kandidat20.jpg'
import kandidat21 from '../assets/candidates/new/kandidat21.jpg'
import kandidat22 from '../assets/candidates/new/kandidat22.jpg'
import kandidat23 from '../assets/candidates/new/kandidat23.jpg'
import kandidat24 from '../assets/candidates/new/kandidat24.jpg'
import kandidat25 from '../assets/candidates/new/kandidat25.jpg'

// import spackovam from '../assets/candidates/1_spackova_eva_m.jpg'
// import buresm from '../assets/candidates/2_bures_richard_m.jpg'
// import scholzm from '../assets/candidates/4_scholz_petr_m.jpg'
// import marikm from '../assets/candidates/5_marik_vladimir_m.jpg'
// import novakm from '../assets/candidates/6_novak_jan_m.jpg'
// import bauerovam from '../assets/candidates/7_bauerova_martina_m.jpg'
// import ouska from '../assets/candidates/8_ouska_tomas_m.jpg'
// import travnicekm from '../assets/candidates/9_travnicek_matej_m.jpg'
// import janderovam from '../assets/candidates/10_janderova_jaroslava_m.jpg'

export default [
  {
    name: 'Eva Špačková',
    image: kandidat1,
    role: 'radní pro školství MČ Praha 1',
    number: '1',
  },
  {
    name: 'Richard Bureš',
    image: kandidat2,
    role: 'zástupce starosty MČ Praha 1',
    number: '2',
  },
  {
    name: 'Tomáš Heres',
    image: kandidat3,
    role: 'zastupitel MČ Praha 1',
    number: '3',
  },
  {
    name: 'Petr Scholz',
    image: kandidat4,
    role: 'podnikatel, živnostník',
    number: '4',
  },
  {
    name: 'Vladimír Mařík',
    image: kandidat5,
    role: 'důchodce',
    number: '5',
  },
  {
    name: 'Jan Novák',
    image: kandidat6,
    role: 'právník',
    number: '6',
  },
  {
    name: 'Martina Mamula',
    image: kandidat7,
    role: 'operní pěvkyně, pedagog',
    number: '7',
  },
  {
    name: 'Tomáš Ouška',
    image: kandidat8,
    role: 'živnostník',
    number: '8',
  },
  {
    name: 'Matěj Trávníček',
    image: kandidat9,
    role: 'politolog, VŠ pedagog',
    number: '9',
  },
  {
    name: 'Jaroslava Janderová',
    image: kandidat10,
    role: 'zastupitelka hl. m. Prahy, právnička',
    number: '10',
  },
  {
    name: 'Olga Krahulcová',
    image: kandidat11,
    role: 'asistentka',
    number: '11',
  },
  {
    name: 'Jiří Cibuzar',
    image: kandidat12,
    role: 'podnikatel',
    number: '12',
  },
  {
    name: 'Josef Ludvíček',
    image: kandidat13,
    role: 'technik',
    number: '13',
  },
  {
    name: 'Michaela Bartáková',
    image: kandidat14,
    role: 'lékařka',
    number: '14',
  },
  {
    name: 'Otakar Pištora',
    image: kandidat15,
    role: 'podnikatel',
    number: '15',
  },
  {
    name: 'Daniel Skalický',
    image: kandidat16,
    role: 'podnikatel',
    number: '16',
  },
  {
    name: 'Karel Tabery',
    image: kandidat17,
    role: 'projektový manažer',
    number: '17',
  },
  {
    name: 'Blanche Demarteni',
    image: kandidat18,
    role: 'podnikatelka',
    number: '18',
  },
  {
    name: 'Hugo Kaminský',
    image: kandidat19,
    role: 'manažer',
    number: '19',
  },
  {
    name: 'Pavel Beneš',
    image: kandidat20,
    role: 'správce nemovitostí',
    number: '20',
  },
  {
    name: 'Ivan Bednář',
    image: kandidat21,
    role: 'podnikatel',
    number: '21',
  },
  {
    name: 'Dana Komišová',
    image: kandidat22,
    role: 'studentka',
    number: '22',
  },
  {
    name: 'Tomáš Turek',
    image: kandidat23,
    role: 'živnostník',
    number: '23',
  },
  {
    name: 'Jana Vernerová',
    image: kandidat24,
    role: 'manažerka',
    number: '24',
  },
  {
    name: 'David Bartoň',
    image: kandidat25,
    role: 'výtvarník',
    number: '25',
  },
]
