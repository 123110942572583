import React, { Component } from 'react'
import Notification from './Notification'
import logo from '../assets/svg/logo-ods.svg'
import { Link } from 'react-router-dom'
import './header.scss'

class Header extends Component {
  render() {
    return (
      <header className="header">
        <div className="header__logo-wrap">
          <Link to="/">
            <img className="header__logo" src={logo} alt="Občanská demokratická strana" />
            <span className="header__prague">Praha 1</span>
          </Link>
        </div>
        <h1 className="header__title">
          <Notification notification={this.props.notification} className="header__notification" />
          {this.props.title}
        </h1>
      </header>
    )
  }
  static defaultProps = {
    title: 'Jednička pro občany',
  }
}

export default Header
