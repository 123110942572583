import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Left from './Left'
import Right from './Right'
import data from '../data'
import './navigation.scss'

class Navigation extends Component {
  getNeighbours() {
    const currentIndex = data.pages[this.props.location.pathname].order
    let prev
    let next
    Object.keys(data.pages).forEach((k, i) => {
      if (data.pages[k].order === currentIndex - 1) {
        prev = data.pages[k]
        prev.link = k
      }
      if (data.pages[k].order === currentIndex + 1) {
        next = data.pages[k]
        next.link = k
      }
    })
    return {
      prev: prev,
      next: next,
    }
  }

  getNext() {}

  render() {
    // const { prev, next, location } = this.props
    const { prev, next } = this.getNeighbours()
    return (
      <nav className="navigation">
        {prev && (
          <Link className="navigation__prev navigation__link" to={prev.link}>
            <Left />
            <span className="navigation__title">{prev.navigation}</span>
          </Link>
        )}
        {next && (
          <Link className="navigation__next navigation__link" to={next.link}>
            <span className="navigation__title">{next.navigation}</span>
            <Right />
          </Link>
        )}
      </nav>
    )
  }
  static defaultProps = {
    prev: {
      link: '/',
      title: 'předchozí',
    },
    next: {
      link: '/',
      title: 'další',
    },
  }
}

export default withRouter(props => <Navigation {...props} />)
