import React from 'react'

const MenuIcon = ({ color = '#3fa7f3' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
    <circle cx="27.5" cy="27.5" r="27.5" style={{ fill: color }} />
    <line x1="13.5" y1="19.75" x2="41.5" y2="19.75" style={{ fill: 'none', stroke: '#fff' }} />
    <line x1="13.5" y1="27.25" x2="41.5" y2="27.25" style={{ fill: 'none', stroke: '#fff' }} />
    <line x1="13.5" y1="35.25" x2="41.5" y2="35.25" style={{ fill: 'none', stroke: '#fff' }} />
  </svg>
)

export default MenuIcon
