import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import MenuIcon from './MenuIcon'
import MenuClose from './MenuClose'
import logo from '../assets/svg/logo-ods-white.svg'
import { Context } from '../context'
import './menu.scss'

class Menu extends Component {
  state = {
    open: false,
    links: [
      {
        link: '/kandidati',
        text: 'Naši Kandidáti',
      },
    ],
  }

  toggleMenu(context, e) {
    const toggle = !this.state.open
    this.setState({
      open: toggle,
    })
  }

  render() {
    const { open } = this.state

    return (
      <Context.Consumer>
        {context => {
          return (
            <div className={cx('menu', { menu__open: open })}>
              <div className="menu__icon" onClick={this.toggleMenu.bind(this, context)}>
                {open ? <MenuClose /> : <MenuIcon />}
              </div>

              <nav onClick={this.toggleMenu.bind(this)}>
                <div className="menu__logo">
                  <Link to="/">
                    <img src={logo} alt="Občanská demokratická strana" />
                  </Link>
                </div>
                <ul>
                  <li>
                    <Link to="/kandidati">Kandidáti</Link>
                  </li>
                  <li>
                    <Link to="/program">Program</Link>
                  </li>
                  <li>
                    <Link to="/priority/1">Dáváme jedníčku do pořádku</Link>
                  </li>
                  <li>
                    <Link to="/priority/2">Parkování pro rezidenty</Link>
                  </li>
                  <li>
                    <Link to="/priority/3">Jednička občanům</Link>
                  </li>
                  <li>
                    <Link to="/priority/4">Krásný život pro všechny generace</Link>
                  </li>
                  <li>
                    <Link to="/magazin">Magazín Jednička</Link>
                  </li>
                  <li>
                    <Link to="/uspechy">Co se povedlo</Link>
                  </li>
                </ul>
              </nav>
            </div>
          )
        }}
      </Context.Consumer>
    )
  }
}

export default Menu
