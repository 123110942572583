import React from 'react'

const Expand = ({ className }) => (
  <svg className={className} viewBox="0 0 55 55">
    <circle cx="27.5" cy="27.5" r="27.5" style={{ fill: '#3fa7f3' }} />
    <line x1="7.37" y1="27.5" x2="47.63" y2="27.5" style={{ fill: 'none', stroke: '#fff' }} />
    <line x1="27.5" y1="7.37" x2="27.5" y2="47.63" style={{ fill: 'none', stroke: '#fff' }} />
  </svg>
)

export default Expand
