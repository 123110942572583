import React, { Component } from 'react'
import Left from '../navigation/Left'
import Right from '../navigation/Right'
import './expand.scss'

class Expand extends Component {
  state = {
    visible: false,
  }

  toggle() {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    const { visible } = this.state
    const text = visible ? 'méně' : 'více'
    return (
      <div className="expand">
        <span className="expand__controls" onClick={this.toggle.bind(this)}>
          {text} {visible ? <Left /> : <Right />}
        </span>
        {this.state.visible && <span className="expand__expanded">{this.props.children}</span>}
      </div>
    )
  }
}

export default Expand
