import React from 'react'
import logo from '../assets/svg/logo-ods.svg'
import { Link } from 'react-router-dom'

const Logo = ({ className }) => (
  <div className="row justify-content-end">
    <div className="col col-md-3 col-sm-12">
      <Link to="/">
        <img className="main__logo" src={logo} alt="Občanská demokratická strana" />
      </Link>
    </div>
  </div>
)

export default Logo
