import React, { Component } from 'react'
import './candidate.scss'

class Candidate extends Component {
  render() {
    const { data } = this.props
    return (
      <div
        className="candidate"
        style={{
          color: 'white',
        }}>
        {/* // <div className="candidate__filter" /> */}
        <div
          className="candidate__photo"
          style={{
            backgroundImage: `url(${data.image})`,
          }}
        />
        <div className="candidate__label">
          <h3 className="candidate__title">{data.name}</h3>
          <span className="candidate__role">{data.role}</span>
        </div>
        <div className="candidate__number">
          <span>{data.number}</span>
        </div>
      </div>
    )
  }
}

export default Candidate
