import React, { Component } from 'react'
import Button from '../button/Button'
import Logo from '../logo/Logo'
import data from '../data'
import Navigation from '../navigation/Navigation'
import './detail.scss'

class Detail extends Component {
  render() {
    const pagedata = data.pages[this.props.location.pathname]
    const Text = pagedata.text
    console.log('detail props', this.props)
    console.log('detail pagedata', pagedata.text)
    return (
      <main className="detail container">
        <Navigation />
        <article className="">
          <div className="row justify-content-center">
            <div className="col-md-8 col-sm-12 col-xs-12">
              <Text />
            </div>
          </div>
        </article>
        <Button to="/">Zpět na výběr</Button>
        <Logo />
      </main>
    )
  }
  static defaultProps = {
    text: `
      Default text
    `,
  }
}

export default Detail
