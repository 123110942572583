import React, { Component } from 'react'
import './footer.scss'

class Footer extends Component {
  render() {
    return (
      <footer className="header">
        <span>Copyright &copy; 2018 ODS. Všechna práva vyhrazena.</span>
        <a href="mailto:info@ods.cz"> Napište nám.</a>
      </footer>
    )
  }
}

export default Footer
