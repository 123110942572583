import React, { Component } from 'react'
import ptak from '../assets/svg/ptak.svg'
import './background.scss'

class Background extends Component {
  render() {
    const { image } = this.props
    return (
      <div
        style={{
          backgroundImage: `url(${image})`,
        }}
        className="background"
      />
    )
  }
  static defaultProps = {
    image: ptak,
  }
}

export default Background
